var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info__inner" }, [
    _c("div", { staticClass: "info__header" }, [
      _c("div", { staticClass: "info__buttons" }, [
        !_vm.user.isBlocked
          ? _c(
              "div",
              {
                staticClass: "button__icon",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  }
                }
              },
              [
                _c("Multiselect", {
                  staticClass: "mSelect-dropdown",
                  attrs: {
                    options: _vm.list,
                    searchable: false,
                    "show-labels": false,
                    "reset-after": true,
                    value: _vm.item,
                    placeholder: "",
                    "open-direction": "bottom"
                  },
                  on: { select: _vm.actions }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "button__icon button--onmobile",
            on: {
              click: function($event) {
                return _vm.$modal.hide("InfoUser")
              }
            }
          },
          [_c("i", { staticClass: "ri-close-fill" })]
        )
      ]),
      _c("div", { staticClass: "info__title-wrapper" }, [
        _c("div", { staticClass: "letter" }, [
          _vm._v(_vm._s(_vm.user.firstName[0]))
        ]),
        _c("div", { staticClass: "info__title" }, [
          _c("span", [_vm._v("ПІБ: ")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.user.lastName) +
                " " +
                _vm._s(_vm.user.firstName) +
                " " +
                _vm._s(_vm.user.fathersName)
            )
          ])
        ]),
        _c("div", { staticClass: "info__title" }, [
          _c("span", [_vm._v("НІК: ")]),
          _c("span", [_vm._v(_vm._s(_vm.user.fullName))])
        ])
      ])
    ]),
    _c("div", { staticClass: "info__main" }, [
      _c("div", { staticClass: "info__list" }, [
        _c("div", { staticClass: "info__item" }, [
          _c("div", { staticClass: "info__item-title" }, [_vm._v("Роль")]),
          _c("div", { staticClass: "info__item-subtitle" }, [
            _vm._v(_vm._s(_vm.role))
          ])
        ]),
        _c("div", { staticClass: "info__item" }, [
          _c("div", { staticClass: "info__item-title" }, [_vm._v("Ранк")]),
          _c("div", { staticClass: "info__item-subtitle" }, [
            _vm._v(_vm._s(_vm.rank))
          ])
        ])
      ]),
      _c("div", { staticClass: "info__list" }, [
        _c("div", { staticClass: "info__item" }, [
          _c("div", { staticClass: "info__item-title" }, [_vm._v("E-mail")]),
          _c("div", { staticClass: "info__item-subtitle" }, [
            _vm._v(_vm._s(_vm.user.email))
          ])
        ]),
        _c("div", { staticClass: "info__item" }, [
          _c("div", { staticClass: "info__item-title" }, [_vm._v("Телефон")]),
          _c(
            "a",
            {
              staticClass: "info__item-subtitle",
              attrs: { href: "tel:" + _vm.user.phone }
            },
            [_vm._v(_vm._s(_vm.user.phone))]
          )
        ]),
        _c("div", { staticClass: "info__item" }, [
          _c("div", { staticClass: "info__item-title" }, [_vm._v("Офіційно")]),
          _vm.user.isOfficial
            ? _c(
                "div",
                {
                  staticClass: "info__item-subtitle",
                  staticStyle: { color: "#3CB958" }
                },
                [_vm._v("Так")]
              )
            : _c("div", { staticClass: "info__item-subtitle" }, [_vm._v("Ні")])
        ]),
        _c("div", { staticClass: "info__item" }, [
          _c("div", { staticClass: "info__item-title" }, [_vm._v("Контракт")]),
          _vm.user.isOnContract
            ? _c(
                "div",
                {
                  staticClass: "info__item-subtitle",
                  staticStyle: { color: "#3CB958" }
                },
                [_c("i", { staticClass: "ri-checkbox-circle-fill" })]
              )
            : _c("div", { staticClass: "info__item-subtitle" }, [
                _c("i", { staticClass: "ri-checkbox-circle-fill" })
              ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }