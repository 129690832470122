var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user__wrapper" }, [
    _c(
      "div",
      {
        staticClass: "user__row",
        class: { "user__row--deleted": _vm.user.isBlocked }
      },
      [
        _c("div", { staticClass: "user__row-left" }, [
          _c(
            "div",
            {
              staticClass: "user__cell user__contract",
              class: { "user__contract--active": _vm.user.isOnContract }
            },
            [_c("i", { staticClass: "ri-checkbox-circle-fill" })]
          ),
          _c("div", { staticClass: "user__cell" }, [
            _c("div", { staticClass: "letter" }, [
              _vm._v(_vm._s(_vm.user.firstName[0]))
            ])
          ]),
          _c("div", { staticClass: "user__cell" }, [
            _c("span", [_vm._v(_vm._s(_vm.user.fullName) + " ")])
          ]),
          _c("div", { staticClass: "user__cell user__official" }, [
            _c(
              "div",
              {
                staticClass: "indicator",
                class: {
                  "indicator--green": _vm.user.isOfficial,
                  "indicator--yellow": !_vm.user.isOfficial
                }
              },
              [
                _vm.user.isOfficial
                  ? _c("span", [_vm._v("ОФІЦІЙНО")])
                  : _c("span", [_vm._v("НЕ ОФІЦІЙНО")])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "user__row-right" }, [
          _c("div", { staticClass: "user__cell user__role" }, [
            _c(
              "div",
              { staticClass: "indicator indicator--rounded indicator--blue" },
              [_c("span", [_vm._v(_vm._s(_vm.role))])]
            )
          ]),
          _c("div", { staticClass: "user__cell user__rank" }, [
            _c(
              "div",
              { staticClass: "indicator indicator--rounded indicator--green" },
              [_c("span", [_vm._v(_vm._s(_vm.rank))])]
            )
          ]),
          !_vm.user.isBlocked
            ? _c(
                "div",
                {
                  staticClass: "user__cell user__actions",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("Multiselect", {
                    staticClass: "mSelect-dropdown",
                    attrs: {
                      options: _vm.list,
                      searchable: false,
                      "show-labels": false,
                      "reset-after": true,
                      value: _vm.item,
                      placeholder: ""
                    },
                    on: { select: _vm.actions }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass: "user__card",
        class: { "user__card--deleted": _vm.user.isBlocked }
      },
      [
        _c("div", { staticClass: "user__card-row" }, [
          _c("div", { staticClass: "user__card-cell" }, [
            _c("div", { staticClass: "letter" }, [
              _vm._v(_vm._s(_vm.user.firstName[0]))
            ]),
            _c("span", [_vm._v(_vm._s(_vm.user.fullName) + " ")])
          ]),
          _c("div", { staticClass: "user__card-cell" }, [
            _c(
              "div",
              { staticClass: "indicator indicator--rounded indicator--green" },
              [_c("span", [_vm._v(_vm._s(_vm.role))])]
            )
          ])
        ]),
        _c("div", { staticClass: "user__card-row" }, [
          _c("div", { staticClass: "user__card-cell user__card-cell--title" }, [
            _vm._v("Ранг")
          ]),
          _c("div", { staticClass: "user__card-cell" }, [
            _c(
              "div",
              { staticClass: "indicator indicator--rounded indicator--green" },
              [_c("span", [_vm._v(_vm._s(_vm.rank))])]
            )
          ])
        ]),
        _c("div", { staticClass: "user__card-row" }, [
          _c("div", { staticClass: "user__card-cell user__card-cell--title" }, [
            _vm._v("Контракт/Офіційно")
          ]),
          _c("div", { staticClass: "user__card-cell" }, [
            _c(
              "div",
              {
                staticClass: "user__card-cell--contract",
                class: {
                  "user__card-cell--contract--active": _vm.user.isOnContract
                }
              },
              [
                _vm.user.isOnContract
                  ? _c("i", { staticClass: "ri-check-line" })
                  : _c("i", { staticClass: "ri-close-fill" })
              ]
            ),
            _c(
              "div",
              {
                staticClass: "user__card-cell--official",
                class: {
                  "user__card-cell--official--active": _vm.user.isOfficial
                }
              },
              [
                _vm.user.isOfficial
                  ? _c("i", { staticClass: "ri-add-line" })
                  : _c("i", { staticClass: "ri-subtract-line" })
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }