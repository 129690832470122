var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "users__wrapper" }, [
      _c(
        "div",
        { staticClass: "users__list" },
        _vm._l(_vm.users, function(user) {
          return _c("User", {
            key: user.id,
            attrs: { user: user },
            nativeOn: {
              click: function($event) {
                return _vm.info(user)
              }
            }
          })
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }