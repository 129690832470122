<template>
  <div class="info__inner">
    <div class="info__header">
      <div class="info__buttons">
        <div v-if="!user.isBlocked" class="button__icon" @click.stop.prevent>
          <Multiselect
            class="mSelect-dropdown"
            :options="list"
            :searchable="false"
            :show-labels="false"
            :reset-after="true"
            :value="item"
            placeholder
            open-direction="bottom"
            @select="actions"
          />
        </div>
        <div class="button__icon button--onmobile" @click="$modal.hide('InfoUser')">
          <i class="ri-close-fill"></i>
        </div>
      </div>
      <div class="info__title-wrapper">
        <div class="letter">{{ user.firstName[0] }}</div>
        <div class="info__title">
          <span>ПІБ: </span>
          <span>{{ user.lastName }} {{ user.firstName }} {{ user.fathersName }}</span>
        </div>
        <div class="info__title">
          <span>НІК: </span>
          <span>{{ user.fullName }}</span>
        </div>
      </div>
    </div>
    <div class="info__main">
      <div class="info__list">
        <div class="info__item">
          <div class="info__item-title">Роль</div>
          <div class="info__item-subtitle">{{ role }}</div>
        </div>
        <div class="info__item">
          <div class="info__item-title">Ранк</div>
          <div class="info__item-subtitle">{{ rank }}</div>
        </div>
      </div>
      <div class="info__list">
        <div class="info__item">
          <div class="info__item-title">E-mail</div>
          <div class="info__item-subtitle">{{ user.email }}</div>
        </div>
        <div class="info__item">
          <div class="info__item-title">Телефон</div>
          <a :href="'tel:' + user.phone" class="info__item-subtitle">{{ user.phone }}</a>
        </div>
        <div class="info__item">
          <div class="info__item-title">Офіційно</div>
          <div v-if="user.isOfficial" class="info__item-subtitle" style="color: #3CB958">Так</div>
          <div v-else class="info__item-subtitle">Ні</div>
        </div>
        <div class="info__item">
          <div class="info__item-title">Контракт</div>
          <div v-if="user.isOnContract" class="info__item-subtitle" style="color: #3CB958">
            <i class="ri-checkbox-circle-fill"></i>
          </div>
          <div v-else class="info__item-subtitle"><i class="ri-checkbox-circle-fill"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Edit from '../Upload'

export default {
  name: 'Infouser',
  props: ['user'],
  components: {
    Multiselect
  },
  data: () => ({
    list: ['Редагувати'],
    item: '',
    constants: null,
    rank: null,
    role: null
  }),
  created() {
    const constants = (this.constants = this.$store.getters.constants)
    this.role = constants.USER_ROLES.find(r => r.id === this.user.role)?.name
    this.rank = constants.USER_RANKS.find(r => r.id === this.user.rank)?.name
  },
  methods: {
    actions(option) {
      if (option === 'Редагувати') {
        this.$modal.display(Edit, {user: this.user}, {name: 'UploadUser', clickToClose: false})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
