<template>
  <div class="user__wrapper">
    <div class="user__row" :class="{'user__row--deleted': user.isBlocked}">
      <div class="user__row-left">
        <div class="user__cell user__contract" :class="{'user__contract--active': user.isOnContract}">
          <i class="ri-checkbox-circle-fill"></i>
        </div>
        <div class="user__cell">
          <div class="letter">{{ user.firstName[0] }}</div>
        </div>
        <div class="user__cell">
          <span>{{ user.fullName }} </span>
        </div>
        <div class="user__cell user__official">
          <div class="indicator" :class="{'indicator--green': user.isOfficial, 'indicator--yellow': !user.isOfficial}">
            <span v-if="user.isOfficial">ОФІЦІЙНО</span>
            <span v-else>НЕ ОФІЦІЙНО</span>
          </div>
        </div>
      </div>
      <div class="user__row-right">
        <div class="user__cell user__role">
          <div class="indicator indicator--rounded indicator--blue">
            <span>{{ role }}</span>
          </div>
        </div>
        <div class="user__cell user__rank">
          <div class="indicator indicator--rounded indicator--green">
            <span>{{ rank }}</span>
          </div>
        </div>
        <div v-if="!user.isBlocked" class="user__cell user__actions" @click.stop.prevent>
          <Multiselect
            class="mSelect-dropdown"
            :options="list"
            :searchable="false"
            :show-labels="false"
            :reset-after="true"
            :value="item"
            placeholder
            @select="actions"
          />
        </div>
      </div>
    </div>
    <div class="user__card" :class="{'user__card--deleted': user.isBlocked}">
      <div class="user__card-row">
        <div class="user__card-cell">
          <div class="letter">{{ user.firstName[0] }}</div>
          <span>{{ user.fullName }} </span>
        </div>
        <div class="user__card-cell">
          <div class="indicator indicator--rounded indicator--green">
            <span>{{ role }}</span>
          </div>
        </div>
      </div>
      <div class="user__card-row">
        <div class="user__card-cell user__card-cell--title">Ранг</div>
        <div class="user__card-cell">
          <div class="indicator indicator--rounded indicator--green">
            <span>{{ rank }}</span>
          </div>
        </div>
      </div>
      <div class="user__card-row">
        <div class="user__card-cell user__card-cell--title">Контракт/Офіційно</div>
        <div class="user__card-cell">
          <div class="user__card-cell--contract" :class="{'user__card-cell--contract--active': user.isOnContract}">
            <i v-if="user.isOnContract" class="ri-check-line"></i>
            <i v-else class="ri-close-fill"></i>
          </div>
          <div class="user__card-cell--official" :class="{'user__card-cell--official--active': user.isOfficial}">
            <i v-if="user.isOfficial" class="ri-add-line"></i>
            <i v-else class="ri-subtract-line"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Edit from '../Upload'
import Multiselect from 'vue-multiselect'

export default {
  name: 'User',
  props: {
    user: {type: Object, default: null}
  },
  components: {Multiselect},
  data() {
    return {
      role: '',
      rank: '',
      list: ['Редагувати'],
      item: ''
    }
  },
  created() {
    const constants = this.$store.getters.constants
    const user = this.user
    this.role = constants.USER_ROLES.find(r => r.id === user.role)?.name
    this.rank = constants.USER_RANKS.find(r => r.id === user.rank)?.name
  },
  methods: {
    actions(option) {
      if (option === 'Редагувати') {
        this.$modal.display(Edit, {user: this.user}, {name: 'UploadUser', width: '700px', clickToClose: false})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
