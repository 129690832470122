<template>
  <div class="container">
    <div class="users__wrapper">
      <div class="users__list">
        <User v-for="user of users" :key="user.id" :user="user" @click.native="info(user)" />
      </div>
    </div>
  </div>
</template>

<script>
import User from './User'
import Info from './Info'
import {mapState} from 'vuex'

export default {
  name: 'Users',
  components: {User},
  computed: {
    ...mapState({
      users: s => s.users.users
    })
  },
  methods: {
    info(user) {
      this.$modal.display(Info, {user}, {name: 'InfoUser', width: 420, height: 388})
    }
  }
}
</script>
